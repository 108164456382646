import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import OtpInput from "react-otp-input";
import { notification } from "antd";
import { useResendOTPByEmail, useVerifyOTPByEmail } from "src/api/user";
import { RouteType } from "src/constants/routeTypes";

const SignInOtp: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [otp, setOtp] = useState<string>("");
  const [timeLeft, setTimeLeft] = useState<number>(60);
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  // Extract loginConfiguration
  const loginConfiguration = location?.state?.userLoginConfiguration;

  // Handle OTP sending and verification
  const {
    error: emailError,
    isSuccess: otpSentIsSuccess,
    isLoading: otpSentIsLoading,
  } = useResendOTPByEmail(query);

  const {
    data,
    mutate: verifyOTP,
    isLoading,
    error,
    isSuccess,
  } = useVerifyOTPByEmail();

  // Set initial timeLeft based on the login configuration
  useEffect(() => {
    if (loginConfiguration?.loginConfiguration?.resendOtpLinkInSeconds) {
      setTimeLeft(
        loginConfiguration?.loginConfiguration.resendOtpLinkInSeconds
      );
    }
  }, [loginConfiguration]);

  // Timer effect to update timeLeft every second
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(
        () => setTimeLeft((prevTime) => prevTime - 1),
        1000
      );
      return () => clearTimeout(timer); // Clean up timer
    }
  }, [timeLeft]);

  // Resend OTP handler
  const resendOTP = () => {
    const email = loginConfiguration?.userEmail;
    if (email) {
      setOtp("");
      setQuery(`email=${email}`);
    }
  };

  // Handle OTP submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loginConfiguration?.userEmail) {
      setLoading(true);

      const payload = {
        emailId: loginConfiguration.userEmail,
        otp,
      };

      // Verify OTP
      verifyOTP(payload);
    }
  };

  // Handle success response from verifyOTP
  useEffect(() => {
    if (isSuccess && data) {
      setLoading(false);

      navigate(RouteType.SUCCESS_SCREEN, {
        state: {
          title: "",
          description: "Login successfully",
          buttonTitle: "Back to login",
        },
      });

      notification["success"]({
        message: "Notification",
        description: "Login successfully",
      });
      // navigate("/next-step"); // Navigate to the next step after verification
    }
  }, [isSuccess, data, navigate]);

  useEffect(() => {
    if (error) {
      setErrorMessage(t(error.message));
      setOtp("");
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (otpSentIsSuccess) {
      notification["success"]({
        message: "",
        description: "OTP sent successfully",
      });
    }

    if (emailError) {
      notification["success"]({
        message: "",
        description: "Enable to send OTP, try again",
      });
    }
  }, [otpSentIsSuccess, emailError]);

  return (
    <div className="signIn">
      <img src={"/images/login_bg.svg"} className="loginBG" alt="Background" />
      <div className="userAuth">
        <div className="userAuthForm userAuthFormResetPassword">
          <form
            autoComplete="off"
            role="form"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="userAuthWTKLogo">
              <img src={"/images/black_logo.svg"} alt="Logo" />
            </div>
            <div className="userAuthLoginText">
              <h5>{t("Acceptance Portal Login")}</h5>
            </div>
            <p style={{ fontSize: "12px", color: "#494949" }}>
              {t(
                `An OTP has been sent to your ${
                  loginConfiguration?.loginConfiguration?.sendOtpVia ===
                  "MOBILE"
                    ? "mobile number"
                    : "email id"
                }. Please verify below.`
              )}
            </p>
            <div
              className="form-group mt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <OtpInput
                value={otp}
                onChange={(value:any) => {
                  setOtp(value);
                  setErrorMessage(""); // Clear error message when OTP changes
                }}
                numInputs={
                  loginConfiguration?.loginConfiguration?.otpLength || 6
                }
                renderSeparator={<span>&nbsp;&nbsp;</span>}
                renderInput={(props:any) => (
                  <input
                    {...props}
                    className={
                      errorMessage === "" ? `otpInput` : `otpInput invalidOTP`
                    }
                    disabled={isLoading}
                  />
                )}
              />
            </div>
            <div style={{ fontSize: "12px", color: "#D20000" }}>
              {errorMessage}
            </div>

            <div
              className="form-group mt-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "11px",
              }}
            >
              <div
                style={{
                  color: "#AAAAAA",
                  marginTop: "2px",
                  visibility: timeLeft <= 0 ? "hidden" : "visible",
                }}
              >
                {timeLeft} {t("Seconds")}
              </div>
              <div style={{ color: "#494949" }}>
                {t("Didn’t receive?")}{" "}
                <span
                  style={{
                    color: "#1E4B83",
                    fontSize: "12px",
                    cursor:
                      timeLeft !== 0 || otpSentIsLoading
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={timeLeft >= 0 ? undefined : resendOTP}
                >
                  {t("Resend OTP")}
                </span>
              </div>
            </div>
            <div className="form-group mt-4">
              <button
                disabled={
                  loading ||
                  otp.length !==
                    (loginConfiguration?.loginConfiguration?.otpLength || 6)
                }
                type="submit"
                className="btn btn-primary signInBtn"
              >
                <span style={{ display: "flex", justifyContent: "center" }}>
                  {t("Verify")} &nbsp;
                  {loading && (
                    <ThreeDots
                      height="19"
                      width="19"
                      color="#000"
                      ariaLabel="three-dots-loading"
                      visible={true}
                    />
                  )}
                </span>
              </button>
            </div>
          </form>
        </div>
        <div className="userCopyright">
          <p>{t("Copyright ©️ 2024 WadzPay Worldwide")}</p>
        </div>
      </div>
    </div>
  );
};

export default SignInOtp;
