import React, { PropsWithChildren, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MerchantDetailsData } from "src/api/models";
import { useUserDetails } from "src/api/user";
import { RouteType } from "src/constants/routeTypes";
import SignedInUser from "src/auth/SignedInUser";
import SignedOutUser from "src/auth/SignedOutUser";
type MerchantContextType = {
  merchantDetails?: MerchantDetailsData;
  isFetching: boolean;
};

export const MerchantContext = React.createContext<MerchantContextType>({
  merchantDetails: undefined,
  isFetching: true,
});

// User Context
type UserContextType = {
  userDetails?: any; // You can adjust the type if needed
  isFetching: boolean;
};

export const UserContext = React.createContext<UserContextType>({
  userDetails: undefined,
  isFetching: true,
});

type Props = PropsWithChildren<{}>;

// This will load the user data if the current user is logged in
export const SignedInUserContextProvider: React.FC<Props> = ({
  children,
}: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { data, isFetching, error, refetch } = useUserDetails(); // Replace with actual user API
  const [userDetails, setUserDetails] = useState<any>();

  useEffect(() => {
    if (data) {
      setUserDetails(data);
    }
  }, [data]);

  useEffect(() => {
    if (!data) {
      refetch();
    }
  }, [pathname]);

  const getAction = () => {
    if (isFetching) {
      return <></>; // Show a loading indicator or blank screen
    }

    if (error && error.message !== "ERROR_MESSAGE.USER_NOT_FOUND") {
      return (
        <div className="alert alert-danger" role="alert">
          <div>{error.message}</div>
          <div>{t("Unable to retrieve user details from the server")}</div>
        </div>
      );
    }

    if (userDetails && pathname === RouteType.USER_DETAILS) {
      return <Navigate to={RouteType.HOME} />;
    }

    if (
      pathname !== RouteType.USER_DETAILS &&
      error?.message === "ERROR_MESSAGE.USER_NOT_FOUND"
    ) {
      return <Navigate to={RouteType.USER_DETAILS} />;
    }

    return (
      <UserContext.Provider value={{ userDetails, isFetching }}>
        {children}
      </UserContext.Provider>
    );
  };

  return getAction();
};

// export const SignedInMerchantContextProvider: React.FC<Props> = ({
//   children
// }: Props) => {
//   const { t } = useTranslation()
//   const { pathname } = useLocation()
//   // const { data, isFetching, error, refetch } = useMerchantDetails()
//   // const [merchantDetailsData, setMerchantData] = useState<MerchantDetailsData>()

//   // useEffect(() => {
//   //   if (data) {
//   //     setMerchantData(data)
//   //   }
//   // }, [data])

//   // useEffect(() => {
//   //   if (!data) {
//   //     refetch()
//   //   }
//   // }, [pathname])

//   const getAction = () => {
//     // Display a blank screen while fetching the merchant details
//     // if (isFetching) {
//     //   return <></>
//     // }

//     // Display the error message if not MERCHANT_NOT_FOUND
//     // if (error && error.message !== "ERROR_MESSAGE.MERCHANT_NOT_FOUND") {
//     //   return (
//     //     <div className="alert alert-danger" role="alert">
//     //       <div>{error.message}</div>
//     //       <div>{t("Unable to retrieve merchant details from the server")}</div>
//     //     </div>
//     //   )
//     // }

//     // Send to home page if merchant details exist and requesting the merchant details page
//     // if (merchantDetailsData && pathname === RouteType.MERCHANT_DETAILS) {
//     //   return <Navigate to={RouteType.HOME} />
//     // }

//     // Send to merchant details page if merchant is not found
//     // if (
//     //   pathname !== RouteType.MERCHANT_DETAILS &&
//     //   error &&
//     //   error.message === "ERROR_MESSAGE.MERCHANT_NOT_FOUND"
//     // ) {
//     //   return <Navigate to={RouteType.MERCHANT_DETAILS} />
//     // }

//     // Otherwise, display the content
//     // return (
//       // <MerchantContext.Provider
//       //   value={{ merchantDetails: merchantDetailsData, isFetching }}
//       // >
//       //   {children}
//       // </MerchantContext.Provider>
//     // )
//   }

//   return getAction()
// }

/**
 * This will load the merchant data if the current user is logged in
 * otherwise it just renders the children
 */
export const MerchantContextProvider: React.FC<Props> = ({
  children,
}: Props) => {
  return (
    <>
      <SignedInUser>
        {/* <SignedInMerchantContextProvider> */}
        <SignedInUserContextProvider>{children}</SignedInUserContextProvider>
        {/* </SignedInMerchantContextProvider> */}
      </SignedInUser>
      <SignedOutUser>{children}</SignedOutUser>
    </>
  );
};
