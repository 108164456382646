import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup"; // Import Yup for validation schema
import { useNavigate, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

import { useGetUserByUUID, useResetPassword } from "src/api/user";
import { aesEncrypt } from "src/helpers/Utils";
import { icons } from "src/utils/icons";
import { RouteType } from "src/constants/routeTypes";

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/,
      "Password must contain at least one uppercase letter, one lowercase letter, one numeric character, and one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password")],
      "Passwords do not match. Please re-enter your new password"
    )
    .required("Confirm Password is required"),
});

type ResetPasswordFormData = {
  password: string;
  confirmPassword: string;
};

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { uuid } = useParams();
  const [linkExpiredError, setLinkExpiredError] = useState("");

  const {
    data: getUserByUUID,
    error: getUserByUUIDError,
    isSuccess: getUserByUUIDValid,
    isLoading: isLoadingGetUserByUUID,
  } = useGetUserByUUID("userUUID=" + uuid);

  const {
    mutate: resetPassword,
    isLoading,
    error,
    isSuccess,
  } = useResetPassword();

  useEffect(() => {
    if (isSuccess) {
      // On success, navigate to the success screen
      navigate(RouteType.SUCCESS_SCREEN);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      // navigate(RouteType.SUCCESS_SCREEN);
    }
  }, [error]);

  // useEffect(() => {
  //   console.log(">>>>>>>>>> mohit getUserByUUID", getUserByUUID);
  // }, [getUserByUUID]);

  useEffect(() => {
    if (getUserByUUIDError) {
      setLinkExpiredError("Link has been expired.");
    }
  }, [getUserByUUIDError]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(validationSchema), // Use yupResolver for validation
  });

  const onReset = async (data: ResetPasswordFormData) => {
    const password = aesEncrypt(data.password);

    const payload: any = {
      userUUID: uuid,
      password: password,
    };

    await resetPassword(payload);
  };

  // Disable paste functionality
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    alert("Pasting is not allowed");
  };

  // Handle space restriction in password fields
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === " ") {
      e.preventDefault(); // Prevent space
    }
  };

  return (
    <div className="signIn">
      <img src={"/images/login_bg.svg"} className="loginBG" />
      <div className="userAuth">
        <div className="userAuthForm userAuthFormResetPassword">
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onReset)} // Using handleSubmit from react-hook-form
            role="form"
            noValidate
          >
            <div className="userAuthWTKLogo">
              <img src={"/images/black_logo.svg"} />
            </div>
            {isLoadingGetUserByUUID && (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5pc 0 5pc 0",
                }}
              >
                <span>
                  <ThreeDots
                    height="35"
                    width="35"
                    color="#000"
                    ariaLabel="three-dots-loading"
                    visible={true}
                  />
                </span>
              </span>
            )}
            {!isLoadingGetUserByUUID ? (
              linkExpiredError !== "" ? (
                <>
                  <div style={{ padding: "3pc 0 0 0" }}>
                    <img src={icons.expiredIcon} />
                    <p style={{ color: "#C21A1A", fontSize: "14px" }}>
                      {linkExpiredError}
                    </p>
                  </div>
                  <div className="mt-5">
                    <a
                      onClick={() => navigate(RouteType.SIGN_IN)}
                      className="forgotPassword"
                      style={{
                        color: " #1E4B83",
                        textDecoration: "underline",
                        fontSize: "14px",
                      }}
                    >
                      {t("Back to Login")}
                    </a>
                  </div>
                  <div className="mt-3">
                    <a
                      onClick={() => navigate(RouteType.FORGOT_PASSWORD)}
                      className="forgotPassword"
                      style={{
                        color: " #1E4B83",
                        textDecoration: "underline",
                        fontSize: "14px",
                      }}
                    >
                      {t("Reset Password")}
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div className="userAuthLoginText">
                    <h5>{t("Set New Password")}</h5>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      {...register("password")} // Register input for password validation
                      placeholder={t("Enter New Password")}
                      type="password"
                      className={`userInput ${
                        errors.password ? "input-error" : ""
                      }`}
                      aria-describedby="PasswordError"
                      aria-autocomplete="both"
                      aria-haspopup="false"
                      autoComplete="new-password"
                      onPaste={handlePaste} // Prevent pasting
                      onKeyDown={handleKeyDown} // Prevent spaces
                    />
                    {errors.password && (
                      <div id="PasswordError" className="signinForm-error">
                        {errors.password.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group mt-3">
                    <input
                      {...register("confirmPassword")} // Register input for confirm password validation
                      placeholder={t("Confirm New Password")}
                      type="password"
                      className={`userInput ${
                        errors.confirmPassword ? "input-error" : ""
                      }`}
                      aria-describedby="ConfirmPasswordError"
                      aria-autocomplete="both"
                      aria-haspopup="false"
                      autoComplete="new-password"
                      onPaste={handlePaste} // Prevent pasting
                      onKeyDown={handleKeyDown} // Prevent spaces
                    />
                    {errors.confirmPassword && (
                      <div
                        id="ConfirmPasswordError"
                        className="signinForm-error"
                      >
                        {errors.confirmPassword.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group mt-4">
                    <button
                      disabled={isLoading}
                      type="submit"
                      className="btn btn-primary signInBtn"
                    >
                      <span
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {t("Continue")}
                        &nbsp;
                        {isLoading && (
                          <span>
                            <ThreeDots
                              height="19"
                              width="19"
                              color="#000"
                              ariaLabel="three-dots-loading"
                              visible={true}
                            />
                          </span>
                        )}
                      </span>
                    </button>
                  </div>
                </>
              )
            ) : null}
          </form>
        </div>
        <div className="userCopyright">
          <p>{t("Copyright ©️  2024 WadzPay Worldwide")}</p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
