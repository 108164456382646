export const LOCAL_ENV_PUBLIC_API_URL = "http://localhost:8080/"

export enum ENV {
  DEV = "dev",
  POC = "poc",
  TESTING = "testing",
  UAT = "uat",
  PREPROD = "preprod",
  PROD = "prod",
  DDF_UAT = "ddf_uat",
  DDF_PROD = "ddf_prod",
  DDF_QA_TWO = "ddf_qa_two",
  DDF_PRE_PROD = "ddf_pre_prod",
  PRIVATE_CHAIN_DEV = "private_chain_dev",
  PRIVATE_CHAIN_TEST = "private_chain_test",
  PRIVATE_CHAIN_PROD = "private_chain_prod",
}

type CongnitoConfig = {
  aws_project_region: string
  aws_cognito_region: string
  aws_user_pools_id: string
  aws_user_pools_web_client_id: string
  oauth: { [key in string]: string }
}

type ENV_VALUES = {
  TYPE: ENV
  PUBLIC_API_URL: string
  COGNITO_CONFIG: CongnitoConfig
  DEFAULT_COUNTRY: string
  DEFAULT_USER_EMAIL?: string
  DEFAULT_USER_PASSWORD?: string
  DEFAULT_USER_PHONE_NUMBER?: string
  VERSION?: string
}

const congnitoConfig: { [key in ENV]: CongnitoConfig } = {
  dev: {
    aws_project_region: "eu-central-1",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_YHGC0AdSw",
    aws_user_pools_web_client_id: "2pge6nhui8edjckr5fts5o0a1u",
    oauth: {}
  },
  uat: {
    aws_project_region: "ap-southeast-1",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_EE1zE8GMT",
    aws_user_pools_web_client_id: "2f7amn9bg5877rkvou8c0b24m0",
    oauth: {}
  },
  poc: {
    aws_project_region: "ap-southeast-1",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_9QyRkkZlp",
    aws_user_pools_web_client_id: "3icfcrqubcueiv2t1i8h56sb11",
    oauth: {}
  },
  testing: {
    aws_project_region: "ap-southeast-1",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_jityQJ9ye",
    aws_user_pools_web_client_id: "qcakkm5vn7tuqo5umda4otuf3",
    oauth: {}
  },
  preprod: {
    aws_project_region: "ap-southeast-1",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_qnF8ucjP1",
    aws_user_pools_web_client_id: "3sk23u48ub5ctr35u47m2o4dos",
    oauth: {}
  },
  prod: {
    aws_project_region: "ap-southeast-1",
    aws_cognito_region: "ap-southeast-1",
    aws_user_pools_id: "ap-southeast-1_qnF8ucjP1",
    aws_user_pools_web_client_id: "3sk23u48ub5ctr35u47m2o4dos",
    oauth: {}
  },
  ddf_uat: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_rvfULdYNo",
    aws_user_pools_web_client_id: "69nabm3pk9rkro74haiaso342f",
    oauth: {}
  },
  ddf_prod: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_oqqjnTDTZ",
    aws_user_pools_web_client_id: "3qg0396almtpalkv4k6gm0nta3",
    oauth: {}
  },
  ddf_qa_two: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_PS760JSW4",
    aws_user_pools_web_client_id: "64arrpvu7fsf2pq77t4j75d0dm",
    oauth: {}
  },
  ddf_pre_prod: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_iMFwXazfG",
    aws_user_pools_web_client_id: "60s0c93rf9v36gbokq3lbu7r49",
    oauth: {}
  },
  private_chain_dev: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_QObKwnwY6",
    aws_user_pools_web_client_id: "743tba3tmdqtpn3ns8tnmhl1ok",
    oauth: {}
  },
  private_chain_test: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_9wTdxNUlV",
    aws_user_pools_web_client_id: "6qk31hio355uipf9qkm8ct5960",
    oauth: {}
  },
  private_chain_prod: {
    aws_project_region: "me-south-1",
    aws_cognito_region: "me-south-1",
    aws_user_pools_id: "me-south-1_bjIhG8odw",
    aws_user_pools_web_client_id: "6anr59nb8dfj1nrjetlcuc76jn",
    oauth: {}
  }
}

const envsMap: { [key in ENV]: ENV_VALUES } = {
  dev: {
    TYPE: ENV.DEV,
    PUBLIC_API_URL: "https://api.dev.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.dev,
    DEFAULT_COUNTRY: "SK",
    VERSION: "1.0.0"
  },
  poc: {
    TYPE: ENV.POC,
    PUBLIC_API_URL: "https://api.poc.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.poc,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  testing: {
    TYPE: ENV.TESTING,
    PUBLIC_API_URL: "https://api.test.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.testing,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  uat: {
    TYPE: ENV.UAT,
    PUBLIC_API_URL: "https://api.uat.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.uat,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  preprod: {
    TYPE: ENV.PREPROD,
    PUBLIC_API_URL: "https://api.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.preprod,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  prod: {
    TYPE: ENV.PROD,
    PUBLIC_API_URL: "https://api.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.prod,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  ddf_uat: {
    TYPE: ENV.DDF_UAT,
    PUBLIC_API_URL: "https://api.ddf1.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.ddf_uat,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  ddf_prod: {
    TYPE: ENV.DDF_PROD,
    PUBLIC_API_URL: "https://api.ddf.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.ddf_prod,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  ddf_qa_two: {
    TYPE: ENV.DDF_QA_TWO,
    PUBLIC_API_URL: "https://api.ddf-qa2.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.ddf_qa_two,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  ddf_pre_prod: {
    TYPE: ENV.DDF_PRE_PROD,
    PUBLIC_API_URL: "https://api.ddf-pre-prod.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.ddf_pre_prod,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  private_chain_dev: {
    TYPE: ENV.PRIVATE_CHAIN_DEV,
    PUBLIC_API_URL: "https://api.privatechain-dev.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.private_chain_dev,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  private_chain_test: {
    TYPE: ENV.PRIVATE_CHAIN_TEST,
    PUBLIC_API_URL: "https://api.privatechain-test.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.private_chain_test,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  },
  private_chain_prod: {
    TYPE: ENV.PRIVATE_CHAIN_PROD,
    PUBLIC_API_URL: "https://api.privatechain.wadzpay.com/",
    COGNITO_CONFIG: congnitoConfig.private_chain_prod,
    DEFAULT_COUNTRY: "IN",
    VERSION: "1.0.0"
  }
}

const getEnvVars = (env: string = ENV.PRIVATE_CHAIN_DEV) => {
  if (!env) return envsMap[ENV.DEV]
  if (env.indexOf(ENV.PRIVATE_CHAIN_PROD) !== -1) return envsMap[ENV.PRIVATE_CHAIN_PROD]
  if (env.indexOf(ENV.PRIVATE_CHAIN_TEST) !== -1) return envsMap[ENV.PRIVATE_CHAIN_TEST]
  if (env.indexOf(ENV.PRIVATE_CHAIN_DEV) !== -1) return envsMap[ENV.PRIVATE_CHAIN_DEV]
  if (env.indexOf(ENV.DEV) !== -1) return envsMap[ENV.DEV]
  if (env.indexOf(ENV.TESTING) !== -1) return envsMap[ENV.TESTING]
  if (env.indexOf(ENV.DDF_PRE_PROD) !== -1) return envsMap[ENV.DDF_PRE_PROD]
  if (env.indexOf(ENV.DDF_PROD) !== -1) return envsMap[ENV.DDF_PROD]
  if (env.indexOf(ENV.PROD) !== -1) return envsMap[ENV.PROD]
  if (env.indexOf(ENV.DDF_UAT) !== -1) return envsMap[ENV.DDF_UAT]
  if (env.indexOf(ENV.UAT) !== -1) return envsMap[ENV.UAT]
  if (env.indexOf(ENV.POC) !== -1) return envsMap[ENV.POC]
  if (env.indexOf(ENV.PREPROD) !== -1) return envsMap[ENV.PREPROD]
  if (env.indexOf(ENV.DDF_QA_TWO) !== -1) return envsMap[ENV.DDF_QA_TWO]
  return envsMap[ENV.PRIVATE_CHAIN_DEV]
}

const env = getEnvVars(ENV.PRIVATE_CHAIN_DEV)

export default env
