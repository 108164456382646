import { t } from "i18next";
import React, { useContext } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import SignedInUser from "src/auth/SignedInUser";
import { UserContext } from "src/context/Merchant";
import { useJwtAuth } from "src/context/JwtAuthContext";
import { useNavigate } from "react-router-dom";
import { RouteType } from "src/constants/routeTypes";
import { LevelContext } from "src/context/Level";

const SignOut: React.FC = () => {
  const { logout } = useJwtAuth();
  const navigate = useNavigate();
    const { setLevelNumber } = useContext(LevelContext);

  // const { user } = useContext(UserContext)
  const { userDetails } = useContext(UserContext);
  dayjs.extend(utc);
  dayjs.extend(tz);

  const dateFormat = () => {
    const localStorageTime = localStorage.getItem("TimeZone");
    if (localStorageTime) {
      const timezone = JSON.parse(localStorageTime).value || "Asia/Dubai";
      const label = JSON.parse(localStorageTime).label;
      const str = label.slice(11);
      return (
        dayjs(Date()).tz(timezone).format("MMMM D, YYYY h:mm A") +
        " " +
        `(${str})`
      );
    } else {
      return (
        dayjs(Date()).tz("Asia/Dubai").format("MMMM D, YYYY h:mm A") +
        " " +
        `(${"Abu Dhabi, Muscat"})`
      );
    }
  };

  return (
    <SignedInUser>
      <div className="d-flex justify-content-end me-1 text-nowrap align-items-center">
        {userDetails && (
          <div className="mx-3 d-none d-lg-block" data-testid="merchantName">
            {userDetails?.userName}
          </div>
        )}
        <button
          className="btn btn-dark border-secondary"
          onClick={() =>{setLevelNumber(0) ;logout()}}
        >
          {t("Sign Out")}
        </button>
      </div>
      <div className="mt-1" style={{ textAlign: "right" }}>
        <span>{dateFormat()}</span>
      </div>
    </SignedInUser>
  );
};

export default SignOut;
