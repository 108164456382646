import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteType } from "src/constants/routeTypes";
import { ResetPasswordForm } from "src/constants/formTypes";
import { useValidationSchemas } from "src/constants/validationSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { ThreeDots } from "react-loader-spinner";
import {
  // useRequestResetPasswordCode,
  useResetPasswordLinkByEmail,
} from "src/api/user";
import env from "src/env";
const RequestResetPasswordCode: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { resetPasswordSchema } = useValidationSchemas();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<ResetPasswordForm>({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: {
      email: env.DEFAULT_USER_EMAIL,
    },
  });

  const [query, setQuery] = useState("");
  const {
    data: userData,
    error: emailError,
    isSuccess,
    isLoading,
  } = useResetPasswordLinkByEmail(query);

  // const {
  //   mutate: requestResetPasswordCode,
  //   isLoading,
  //   isSuccess,
  //   error,
  //   reset: resetRequestResetPasswordCode,
  // } = useRequestResetPasswordCode();

  const onSendResetPasswordEmail = async () => {
    const { email } = getValues();
    setQuery("email=" + email);
  };

  // useEffect(() => {
  //   async function fetchMyAPI() {
  //     const { email } = getValues();
  //     if (email != "") {
  //       await requestResetPasswordCode({ email });
  //     }
  //   }
  //   fetchMyAPI();
  // }, [userData]);

  useEffect(() => {
    if (isSuccess) {
      // // const { email, newPassword } = getValues()
      // const { email } = getValues();
      // navigate(RouteType.SUBMIT_RESET_PASSWORD, {
      //   // state: { email: email, newPassword: newPassword }
      //   state: { email: email },
      // });

      navigate(RouteType.SUCCESS_SCREEN, {
        state: {
          title: "",
          description:
            "An email has been sent to your registered email address with a link to reset your password",
          buttonTitle: "Back to login",
        },
      });

      // resetRequestResetPasswordCode();
    }
  }, [isSuccess]);

  return (
    <div className="signIn">
      <img src={"/images/login_bg.svg"} className="loginBG" />
      <div className="userAuth">
        <div className="userAuthForm" style={{ width: "283px" }}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onSendResetPasswordEmail)}
            role="form"
            noValidate
          >
            {/* {error && (
              <div className="alert alert-danger" role="alert">
                {error.message}
              </div>
            )} */}
            {emailError && (
              <div className="alert alert-danger" role="alert">
                {emailError.message}
              </div>
            )}
            <div className="userAuthWTKLogo">
              <img src={"/images/black_logo.svg"} />
            </div>
            <div className="userAuthLoginText">
              <h5>{t("User Details")}</h5>
              {/* <p>{t("Make every moment special with WadzPay.")}</p> */}
            </div>
            <div className="form-group mt-4">
              <input
                {...register("email")}
                /*                 autoComplete="true"
                 */ placeholder={t("Email Address")}
                type="email"
                data-testid="email"
                className={`userInput ${
                  errors.email?.message ? "is-invalid" : ""
                }`}
                aria-describedby="emailError"
                value={location?.state?.email}
              />

              {errors.email?.message && (
                <div id="emailError" className="invalid-feedback">
                  {errors.email?.message}
                </div>
              )}
            </div>
            <div className="form-group mt-4">
              <button
                type="submit"
                className="btn btn-primary signInBtn"
                disabled={isLoading}
              >
                <span style={{ display: "flex", justifyContent: "center" }}>
                  {t("Send Verification Link")}
                  &nbsp;
                  {isLoading && (
                    <span>
                      <ThreeDots
                        height="19"
                        width="19"
                        color="#000"
                        ariaLabel="three-dots-loading"
                        visible={true}
                      />
                    </span>
                  )}
                </span>
              </button>
              <div className="mt-4">
                <a
                  onClick={() => navigate(RouteType.SIGN_IN)}
                  className="forgotPassword"
                  style={{
                    color: " #1E4B83",
                    textDecoration: "underline",
                    fontSize: "14px",
                  }}
                >
                  {t("Back to Login")}
                </a>
              </div>
            </div>
          </form>
        </div>
        <div className="userCopyright">
          <p>{t("Copyright ©️ 2024 WadzPay Worldwide.")}</p>
        </div>
      </div>
    </div>
  );
};

export default RequestResetPasswordCode;
