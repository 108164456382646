import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useJwtAuth } from "../context/JwtAuthContext"; // Import your authentication context
import { RouteType } from "../constants/routeTypes";

const PrivateRoute: React.FC = () => {
  const { token } = useJwtAuth(); // Get the token from your JWT context

  // If the token exists, allow access to the protected routes; otherwise, redirect to sign-in
  return token ? <Outlet /> : <Navigate to={RouteType.SIGN_IN} />;
};

export default PrivateRoute;
