import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useJwtAuth } from "../context/JwtAuthContext"; // Import authentication context
import { RouteType } from "../constants/routeTypes";

const PublicRoute: React.FC = () => {
  const { token } = useJwtAuth(); // Get the token from your JWT context

  // If the token exists, redirect to home; otherwise, render the public route
  return token ? <Navigate to={RouteType.HOME} /> : <Outlet />;
};

export default PublicRoute;
