import React, { createContext, useContext, useState, ReactNode } from "react";

interface JwtAuthContextProps {
  token: string | null;
  login: (currentToken: string) => void; // Only accept the JWT token
  logout: () => void;
}

const JwtAuthContext = createContext<JwtAuthContextProps | undefined>(
  undefined
);

export const useJwtAuth = (): JwtAuthContextProps => {
  const context = useContext(JwtAuthContext);
  if (!context) {
    throw new Error("useJwtAuth must be used within a JwtAuthProvider");
  }
  return context;
};

interface JwtAuthProviderProps {
  children: ReactNode;
}

export const JwtAuthProvider: React.FC<JwtAuthProviderProps> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null>(
    () => sessionStorage.getItem("currentToken") || null
  );

  const login = (currentToken: string) => {
    setToken(currentToken);
    sessionStorage.setItem("currentToken", currentToken); // Store only the JWT token
    sessionStorage.setItem("verified", "true");
  };

  const logout = () => {
    setToken(null);
    sessionStorage.removeItem("currentToken"); // Remove the token on logout
    sessionStorage.removeItem("verified");
  };

  return (
    <JwtAuthContext.Provider value={{ token, login, logout }}>
      {children}
    </JwtAuthContext.Provider>
  );
};
