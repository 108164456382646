import React from "react";
import { useTranslation } from "react-i18next";
import { RouteType } from "src/constants/routeTypes";
import { useNavigate, useLocation } from "react-router-dom";

const ResetSuccess: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="signIn">
      <img src={"/images/login_bg.svg"} className="loginBG" />
      <div className="userAuth">
        <div className="userAuthForm" style={{ width: "283px" }}>
          <div className="userAuthWTKLogo">
            <img src={"/images/black_logo.svg"} />
          </div>
          <h5 style={{ fontSize: "18px", color: "#000" }}>
            {location?.state?.title
              ? t(location?.state?.title)
              : t("Set New Password")}
          </h5>
          <img className="mt-3" src={"/icons/success.svg"} />
          <p
            className="mt-1 m-3"
            style={{ fontSize: "14px", color: "#27AE60", width: "200px" }}
          >
            {location?.state?.description
              ? t(location?.state?.description)
              : t("Password created successfully")}
          </p>
          <div className="form-group mt-4">
            <div className="mt-4">
              <a
                onClick={() => navigate(RouteType.SIGN_IN)}
                className="forgotPassword"
                style={{
                  color: " #1E4B83",
                  textDecoration: "underline",
                  fontSize: "14px",
                }}
              >
                {location?.state?.buttonTitle
                  ? t(location?.state?.buttonTitle)
                  : t("Login to Your Account")}
              </a>
            </div>
          </div>
        </div>
        <div className="userCopyright">
          <p>{t("Copyright ©️ 2024 WadzPay Worldwide.")}</p>
        </div>
      </div>
    </div>
  );
};

export default ResetSuccess;
