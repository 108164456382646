import React, { useState } from "react";
import { Modal, Button, Input, Card, Typography, Table, Form } from "antd";
//import './acceptModal.css'
const { TextArea } = Input;
const { Text } = Typography;

const AcceptPopover = ({
  close,
  visible,
  onCancel,
  role,
  onApprove,
  onReject,
  onRefer,
  show,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(show);
  const [form] = Form.useForm(); // Initialize form instance

  const [commentsCheck, setCommentsCheck] = useState(false)
  //console.log("AcceptPopover=========   ", isModalVisible, show, role);
  const showModal = () => {
    setIsModalVisible(true);
  };
  function handleApprove() {
    setCommentsCheck(false)
    form.validateFields().then(values => {
      onApprove(values.comments,'ACTIVE'); // Pass comments to onReject action
    }).catch(errorInfo => {
      // Handle validation error
    });
      }
  function handleReject() {
setCommentsCheck(true)
form.validateFields().then(values => {
  onReject(values.comments,'REJECTED'); // Pass comments to onReject action
}).catch(errorInfo => {
  // Handle validation error
  console.log("Validation Failed:", errorInfo);
});
  }
  function handleRefer() {
    setCommentsCheck(true)
    form.validateFields().then(values => {
      onRefer(values.comments,'REFERRED'); // Pass comments to onReject action
    })  }

  const columns = [
    {
      title: "ROLE NAME",
      dataIndex: "roleName",
      key: "roleName",
    },
    {
      title: "REQUESTED BY",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "UPDATED ON",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
  ];

  const data = [
    {
      key: "1",
      roleName: "WadzPay Administrator",
      requestedBy: "Andrew Murdoch",
      updatedOn: "15 May 2024, 11:30am",
    },
  ];

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    close(false);
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Role Creation - Refer/Reject"
        open={show}
        onCancel={handleCancel}
        footer={null}
        centered
        width={600}
      >
        <div
          style={{
            borderBottom: "1px solid #e0e0e0",
            marginBottom: "20px",
            width: "100%",
          }}
        />

        <Table
          columns={columns}
          dataSource={[role]}
          pagination={false}
          bordered
          style={{ marginBottom: "20px" }}
        />
<Form form={form}>        <div>

          <Text strong>Comment</Text>
          <Form.Item
                                name={"comments"}
                                initialValue={form.getFieldValue('comments')}
/*                                 initialValue={comment}                                
 */                                rules={[
                                  {
                                    required: commentsCheck,
                                    message: "Please add comment",                                    
                                  }
                                       ]}
                               /*  validateStatus={
                                  roleChecked == "" ? "success" : "error"
                                }
                                help={
                                  roleNameError != "" ? roleNameError : null
                                } */
                              >
                              
          <Input.TextArea
            rows={4}
            required={commentsCheck}
           
           // defaultValue="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type"
          />
           </Form.Item>
        </div>

        <div style={{ marginTop: "10px", fontSize: "12px", color: "#555" }}>
          <p>
            <strong>Refer</strong>: Requester Should be able to resubmit the
            request after modification.
          </p>
          <p>
            <strong>Reject</strong>: Requester Cannot resubmit the request, New
            request need to be initiated if required.
          </p>
        </div>
        <div
          style={{
            borderTop: "1px solid #e0e0e0",
            marginTop: "10px",
            paddingTop: "20px",
            textAlign: "right",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        >
          {/*       <div style={{ textAlign: 'right', marginTop: '20px' }}>
           */}{" "}
          <Button
            type="primary"
            onClick={handleApprove}
            style={{
              backgroundColor: "#28a745",
              borderColor: "#28a745",
              marginRight: "10px",
            }}
          >
            Approve
          </Button>
          <Button
            type="default"
            onClick={handleReject}
            style={{
              backgroundColor: "#ff4d4f",
              borderColor: "#ff4d4f",
              marginRight: "10px",
            }}
          >
            Reject
          </Button>
          <Button
            type="default"
            onClick={handleRefer}
            style={{
              backgroundColor: "#ffbf00",
              borderColor: "#ffbf00",
              color: "#fff",
            }}
          >
            Refer
          </Button>
        </div>
        </Form>
      </Modal>
    </>
  );
};

export default AcceptPopover;
