import React, { ReactNode } from "react";
import { useJwtAuth } from "src/context/JwtAuthContext";

const SignedInUser: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { token } = useJwtAuth();

  return <>{token && children}</>;
};

export default SignedInUser;
