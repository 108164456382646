import React, { ReactNode, useEffect } from "react";
import { useJwtAuth } from "src/context/JwtAuthContext";

const SignedOutUser: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { token } = useJwtAuth();

  useEffect(() => {
    const sidebar = document.getElementById("navigation-container");
    const header = document.getElementById("topHeader");
    const mainContainer = document.getElementById("main-container");

    console.log(">>>>>>>>> mohit token token", token);

    if (!token) {
      // Hide UI components if the user is signed out
      if (sidebar) sidebar.style.display = "none";
      if (header) header.style.display = "none";
      if (mainContainer) mainContainer.style.height = "0";
    } else {
      // Show UI components if the user is signed in
      if (sidebar) sidebar.style.display = "block";
      if (header) header.style.display = "block";
      if (mainContainer) mainContainer.style.height = "160vh";
    }
  }, [token]);

  return <>{!token && children}</>;
};

export default SignedOutUser;
