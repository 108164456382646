import { yupResolver } from "@hookform/resolvers/yup";
import React, { ClipboardEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { RouteType } from "src/constants/routeTypes";

import { useJwtAuth } from "src/context/JwtAuthContext";
import { aesEncrypt } from "src/helpers/Utils";
import { useLogin } from "../api/user";
import { SignInForm } from "../constants/formTypes";
import { useValidationSchemas } from "../constants/validationSchemas";
import env from "../env";

const SignIn: React.FC = () => {
  const [showPwdIcon, setPwdIcon] = useState(false);
  const [pwdInputType, setPwdInputType] = useState("password");
  const emailRef = useRef<HTMLInputElement>(null); // Specify the type of the ref
  // const [userLoginConfiguration, setUserLoginConfiguration] = useState<any>();
  // const {
  //   user,
  //   isLoading: loadingUser,
  //   error: userError,
  //   verified,
  // } = useContext(UserContext);
  const { login } = useJwtAuth(); // Add the JWT context

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signInSchema } = useValidationSchemas();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SignInForm>({
    resolver: yupResolver(signInSchema),
    defaultValues: {
      email: env.DEFAULT_USER_EMAIL,
      password: env.DEFAULT_USER_PASSWORD,
    },
  });

  // const { mutate: signIn, isLoading, error, isSuccess } = useSignIn();
  const { data: loginDetails, mutate: signIn, isLoading, error } = useLogin();

  // useEffect(() => {
  //   if (loginDetails) {
  //     setUserLoginConfiguration(loginDetails);
  //   }
  // }, [loginDetails]);

  useEffect(() => {
    if (loginDetails) {
      const { token, loginConfiguration }: any = loginDetails;

      if (loginConfiguration?.isMultifactorEnable) {
        navigate(RouteType.SIGN_IN_OTP, {
          state: { loginDetails },
        });
      } else {
        login(token); // Call the login method from the JWT context

        navigate(RouteType.HOME);
      }
    }
  }, [loginDetails]);

  useEffect(() => {
    if (error) {
      setValue("password", "");
      // setValue("email", "")
    }
  }, [error]);

  const onLogin = (data: SignInForm) => {
    const { email, password } = data;
    const encryptPassword = aesEncrypt(password);
    signIn({ emailId: email, password: encryptPassword });
  };

  const handleClipboardEvent = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    return false;
  };

  const changePWDIconType = () => {
    setPwdIcon(!showPwdIcon);
    if (pwdInputType == "text") {
      setPwdInputType("password");
    } else {
      setPwdInputType("text");
    }
  };

  const forgotPassword = () => {
    // const email = document.getElementById("email");
    // navigate(RouteType.FORGOT_PASSWORD, {
    //   state: { email },
    // });

    navigate(RouteType.FORGOT_PASSWORD);
  };

  return (
    <div className="signIn">
      <img src={"/images/login_bg.svg"} className="loginBG" />
      <div className="userAuth">
        <div className="userAuthForm">
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onLogin)}
            role="form"
            noValidate
          >
            {error &&
              error.message !== "INVALID_PASSWORD" &&
              !error.message.includes("Incorrect Password") &&
              error && (
                <div className="alert alert-danger" role="alert">
                  {error && t(error.message)}
                </div>
              )}
            <div className="userAuthWTKLogo">
              <img src={"/images/black_logo.svg"} />
            </div>
            <div className="userAuthLoginText">
              <h5>{t("Acceptance Portal Login")}</h5>
              {/* <p>{t("Make every moment special with WadzPay.")}</p> */}
            </div>
            <div className="form-group mt-3">
              <input
                {...register("email")}
                placeholder={t("Email Address")}
                type="email"
                data-testid="email"
                className={`userInput ${
                  errors.email?.message ? "is-invalid border border-danger" : ""
                }`}
                aria-describedby="emailError"
                aria-autocomplete="both"
                aria-haspopup="false"
                id="email"
                // ref={emailRef}
                /*                 autoComplete="new-password"
                 */
              />
              {errors.email?.message && (
                <div id="emailError" className="invalid-feedback">
                  {errors.email?.message}
                </div>
              )}
            </div>
            <div className="form-group mt-3">
              <div
                className={`loginPassword ${
                  errors.password?.message ||
                  (error && error.message.includes("Incorrect Password"))
                    ? "is-invalid border border-danger"
                    : ""
                }`}
              >
                <input
                  {...register("password")}
                  placeholder={t("Password")}
                  type={pwdInputType}
                  data-testid="password"
                  className={`userInput`}
                  aria-describedby="passwordError"
                  onPaste={handleClipboardEvent}
                  onKeyDown={(event) => {
                    if (
                      (event.ctrlKey && event.key === "z") ||
                      (event.metaKey && event.key === "z")
                    ) {
                      event.preventDefault();
                      return false;
                    }
                    if (event.ctrlKey && event.shiftKey && event.key === "z") {
                      event.preventDefault();
                      return false;
                    }
                  }}
                />
                <div onClick={() => changePWDIconType()}>
                  {showPwdIcon ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              </div>
              {errors.password?.message && (
                <div id="emailError" className="signinForm-error">
                  {errors.password?.message}
                </div>
              )}
              {!errors.password?.message &&
                error &&
                error.message.includes("Incorrect Password") && (
                  <div id="emailError" className="signinForm-error">
                    {error?.message}
                  </div>
                )}
            </div>
            <div className="form-group mt-4">
              <button
                type="submit"
                className="btn btn-primary signInBtn"
                disabled={isLoading}
              >
                <span style={{ display: "flex", justifyContent: "center" }}>
                  {t("Login")}
                  &nbsp;
                  {isLoading && (
                    <span>
                      <ThreeDots
                        height="19"
                        width="19"
                        color="#000"
                        ariaLabel="three-dots-loading"
                        visible={true}
                      />
                    </span>
                  )}
                </span>
              </button>
              <div className="mt-4">
                <a
                  onClick={forgotPassword}
                  className="forgotPassword"
                  style={{
                    color: " #1E4B83",
                    textDecoration: "underline",
                    fontSize: "14px",
                  }}
                >
                  {t("Forgot Password?")}
                </a>
              </div>
            </div>
          </form>
        </div>
        <div className="userCopyright">
          <p>{t("Copyright ©️  2024 WadzPay Worldwide")}</p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
